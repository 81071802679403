import {
  useCallback, useEffect, useState
} from 'react'
import { useDropzone } from 'react-dropzone'
import s from 'styled-components'
import cn from 'classnames'

import Button from 'components/base/Button'
import Text from 'components/typo/Text'
import Box from 'layout/Box'
import Spinner from 'components/base/Spinner'

import { colors } from 'theme/index'

const getColor = ({
  hover,
  theme,
  isDragReject,
  isDragActive,
  isDragAccept,
  $isDisabled
}) => {
  if (!$isDisabled) {
    if (isDragAccept || hover || isDragActive) {
      return theme.colors.black
    }
    if (isDragReject) {
      return theme.colors.red
    }
  }

  return theme.colors.grey500
}

const styles = (props) => {
  const {
    $size,
    $height,
    $bgColor,
    $warning,
    theme,
  } = props
  const componentTheme = theme.components.dropzone
  const sizes = componentTheme.sizes[$size] || componentTheme.sizes.normal

  return `
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${sizes.width};
    height: ${$height || sizes.height};
    padding: 24px;
    color: ${getColor(props)};
    background-color: ${$bgColor ? theme.colors[$bgColor] || $bgColor : theme.colors.transparent};
    border-radius: ${sizes.br || theme.radius.small};
    border: 2px dashed ${theme.colors.grey200};
    outline: none;
    cursor: pointer;
    transition: border 0.3s ease-in-out;
    ${$warning ? 'border-color: red !important;' : ''}

    &.--active,
    &:hover {
      border-color: ${theme.colors.blue};
    }

    &.--disabled {
      &:hover {
        color: ${theme.colors.grey500} !important;
        border-color: ${theme.colors.grey200} !important;
        cursor: initial;
      }
    }

    &.--button {
      width: auto;
      height: ${$height || 'auto'};
      background-color: unset;
      padding: 0;
    }

    &.--button {
      pointer-events: none;
    }
  `
}

const DropzoneContainer = s.div`
  ${(props) => styles(props)}
`

const FilesDropzone = ({
  onUpload = () => {},
  size = 'big',
  progress = null,
  forceUpload = null,
  isFetching = false,
  isButton = false,
  isLoading = false,
  renderItem = null,
  multiple = false,
  buttonProps = {},
  accept = 'image/*',
  bgColor = '',
  height = 'auto',
  isDisabled = false,
  warning = null,
  ...otherProps
}) => {
  const [ isHover, setHover ] = useState(false)
  const [ files, setFiles ] = useState(null)
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    inputRef,
  } = useDropzone({
    accept,
    onDrop: (acceptedFiles) => {
      setFiles(
        [ ...acceptedFiles.map((acceptedFile) => Object.assign(acceptedFile, { preview: URL.createObjectURL(acceptedFile) })) ]
      )
    },
  })

  useEffect(() => {
    if (forceUpload !== null && inputRef.current) {
      inputRef.current.click()
    }
    // eslint-disable-next-line
  }, [ forceUpload ])

  const handleUpload = () => {
    onUpload(files)
    setFiles(null)
  }

  useEffect(() => {
    if (files) {
      handleUpload()
    }
    // eslint-disable-next-line
  }, [ files ])

  const handleMouseEnter = useCallback(() => setHover(true), [ setHover ])

  const handleMouseLeave = useCallback(() => setHover(false), [ setHover ])

  const renderProgress = useCallback((color = 'grey700') => (
    <Text size="small" color={color}>
      Загрузка
      {' '}
      {String(progress || 0)}
      %
    </Text>
  ), [ progress ])

  return (
    <DropzoneContainer
      hover={isHover}
      {...getRootProps({
        isDragActive,
        isDragAccept,
        isDragReject,
      })}
      $isDisabled={isDisabled}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={cn('s-dropzone', {
        '--button': isButton || renderItem,
        '--disabled': isLoading || isDisabled,
        '--active': isDragActive && !isDisabled,
        '--hover': isHover && !isDisabled,
      })}
      $size={size}
      $height={height}
      $bgColor={bgColor}
      $warning={warning}
      {...otherProps}
    >
      {!isLoading && !isDisabled && <input {...getInputProps()} multiple={multiple} />}
      {renderItem
        ? renderItem({ isLoading })
        : isButton ? (
          <Button
            isGradient
            title="Добавить файл"
            size="big"
            full
            hoverStop={isLoading}
            {...buttonProps}
          >
            {isLoading && (
              <Box ai="center">
                <Spinner
                  className="mr-2"
                  fillColor={colors.white}
                  relative
                  size="xs"
                />
                {renderProgress('blue')}
              </Box>
            )}
          </Button>
        ) : (
          <Box
            direction="column"
            maxWidth="270px"
            jc="center"
            ai="center"
            textAlign="center"
          >
            {(files && !isFetching) ? (
              <>
                {files.map((f) => {
                  <Text className="mb-2">{f.name}</Text>
                })}
                { renderProgress() }
              </>
            ) : (
              <>
                <Text className="mb-2">
                  Перетащите файл или
                  <br />
                  нажмите на кнопку «Загрузить»
                </Text>
                <Text size="normalSm" color="grey500" className="mb-2">
                  Допустимые форматы: jpg, doc, png, pdf, dicom (zip)
                </Text>
                <Button
                  isGradient
                  title="Загрузить"
                  size="small"
                  isDisabled={isDisabled}
                  hoverStop={isLoading || isDisabled}
                  br="100px"
                />
              </>
            )}
          </Box>
        )}
    </DropzoneContainer>
  )
}

export default FilesDropzone
